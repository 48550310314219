// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-book-js": () => import("/opt/build/repo/src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/opt/build/repo/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-events-advantage-of-a-setting-sun-index-js": () => import("/opt/build/repo/src/pages/events/advantage-of-a-setting-sun/index.js" /* webpackChunkName: "component---src-pages-events-advantage-of-a-setting-sun-index-js" */),
  "component---src-pages-events-bibliotheca-24-index-js": () => import("/opt/build/repo/src/pages/events/bibliotheca-24/index.js" /* webpackChunkName: "component---src-pages-events-bibliotheca-24-index-js" */),
  "component---src-pages-events-index-js": () => import("/opt/build/repo/src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-metaculture-reading-room-index-js": () => import("/opt/build/repo/src/pages/events/metaculture-reading-room/index.js" /* webpackChunkName: "component---src-pages-events-metaculture-reading-room-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-void-index-js": () => import("/opt/build/repo/src/pages/void/index.js" /* webpackChunkName: "component---src-pages-void-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

