import Typography from 'typography';

const typography = new Typography({
  baseFontSize: '18px',
  baseLineHeight: 1.45,
  headerFontFamily: [
    'Courier',
    'monospace',
  ],
  bodyFontFamily: [
    'Courier', 
    'monospace',
  ],
});

export default typography;
